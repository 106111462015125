.post-div {
    min-height: 100vh;
    width: 60vw;
    padding: 100px 0em 50px 0em;
    margin: 0 auto;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.post-container {
    min-height: 100vh;
    width: 100vw;
    background-color: #fff;
    color: var(--color-tertiary);
}
.post-body {
    line-height: 2;
    text-align: start;
    /* padding: 10px; */
}
.post-img-div {
    width: 100%;
    min-height: 250px;
}
.sub-head {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    position: relative;
}
.post-header img {
    width: 30px;
    height: 30px;
    transform: translateY(10px);
}
.me-avathar {
 border-radius: 50%;
}
.auth-pub {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.auth-pub h4 {
    font-size: 1em;
    margin: 5px 8px;
    font-weight: 300px;
    opacity: 60%;
}
.sub-head .b-tag{
/* position: absolute; */
right: 0;
}

.sub-head-author-section {
    display: flex;
}
@media (max-width: 1000px) {
    .post-div {
        padding: 40px 20px 50px 20px;
        width: 100vw;
    }

    .sub-head {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .auth-pub h4 {
        font-size: 12px;
        margin: 5px 8px;
        font-weight: 300px;
        opacity: 80%;
    }
  }
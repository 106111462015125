.blog-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 100px 8em 0px 8em;
}

.blog-div-2 {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  width: 100%;
  padding: 5px 8em 80px 8em;
}


@media (max-width: 1000px) {
  .blog-div {
    /* height: 100vh; */
    padding: 50px 20px;
    grid-template-columns: auto;
  }
  .blog-div-2 {
    /* height: 100vh; */
    padding: 50px 20px;
    grid-template-columns: auto;
  }
}

.dashboard-div {
  background-color: rgb(237, 243, 255);
  min-height: 100vh;
  padding: 80px 1em 50px 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.dashnav {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.dashnav button {
  padding: 10px 25px;
  height: 50px;
  border: none;
  background-color: var(--color-primary);
  color: aliceblue;
  cursor: pointer;
}
.dash-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.dashnav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.dashnav-item button{
  margin: 20px 20px;
  padding: 0 60px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease;
}
.dashnav-item button:hover {
  background-color: #22466e;
}


@media(max-width: 900px) {
  .dashnav {
    min-height: 80px;
    display: flex;flex-direction: column;
    align-items: center;
    justify-content: center;
}
  .dashnav-list {
    flex-direction: column;
    margin: 0;
}
.dashnav-item button{
  width: 80vw;
  height: 50px;
}

}
.loader {
    border: 12px solid var(--color-secondary);
    border-radius: 50%;
    border-top: 12px solid var(--color-primary);
    width: 80px;
    height: 80px;
    -webkit-animation: spin .7s linear infinite; /* Safari */
    animation: spin .7s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
footer {
width: 100%;
}
.footer-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  background-color: var(--color-tertiary);
  color: #fff;
}

.footer-social ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin: 0;
}

.footer-social ul li {
  font-size: 25px;
  padding-top: 5px;
  margin: 18px;
}
.footer-social ul li:hover {
  color: var(--color-primary);
}
.copyright-text {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}
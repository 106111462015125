
.primary-btn {
  background: var(--color-tertiary);
  border: 1px solid var(--color-tertiary);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 10px 25px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  margin-right: 10px;
  min-width: 105px;
}
.primary-btn:hover {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.secondary-btn {
  border: 1px solid var(--color-tertiary);
  color: var(--color-tertiary);
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  opacity: 1;
  padding: 10px 25px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  min-width: 105px;
  margin-right: 10px;

}
.secondary-btn:hover {
  color: #ffffff;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.tertiary-btn {
  background: #ffffff;
  border: 1px solid var(--color-primary);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: var(--color-primary);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  opacity: 1;
  padding: 10px 25px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  min-width: 105px;

}

.tertiary-btn:hover {
  background: var(--color-tertiary);
  border: 1px solid var(--color-tertiary);
  color: #ffffff;
}
.blog-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .2s ease-in;
    cursor: pointer;
    width: 300px;
    height: 450px;
    margin: 20px;
    user-select:none;
  }
  .blog-card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .blog-img {
    height: 40%;
    width: 100%;
    position: relative;
    color: var(--color-tertiary);
  }
  
  .blog-detail {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .b-title {
    font-size: 18px;
    font-weight: 500;
    margin: 10px;
  }
  .b-detail {
    font-size: 14px;
    font-weight: 300;
    margin: 0 10px;
    text-align: justify;
  }

  .b-button {
    /* margin: 10px; */
    display: flex;
    position: absolute;
    bottom: 10px;
  }
  .p-date {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-size: 10px;
    font-weight: 300;
  }
  .p-author {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 10px;
    font-weight: 300;
  }
  .b-btn {
    width: 100px;
    height: 28px;
    margin: 5px 10px 10px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
    transition: .2s ease-in;
  }
  .b-btn:hover {
    background-color: var(--color-tertiary);
    color: #fff;
  }

  .b-tag {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5px;
    /* justify-content: space-between; */
  }
  .tag {
    font-size: 9px;
    color: var(--color-tertiary);
    padding: 3px 6px;
    margin: 10px 10px;
    margin-left: 0;
    border-radius: 50px;
  }

.blog-card-link {
  user-select: none;
}
.gallery-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gallery-card {
  padding: 10px;
  width: 300px;
  background-color: var(--color-secondary);
}
.gallery-img {
  width: 100%;
}
.login-div {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  background-color: rgb(237, 243, 255);

}

.login-error {
  width: 100%;
  height: 20px;
  color: brown;
}

.login-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 380px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px;
}

.login-container input {
  color: var(--color-tertiary);
  font-weight:500;
  font-size: 18px;
  line-height: 22px;
  background-color: transparent;
  border:2px solid var(--color-primary);
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}
.login-container h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

.login-container button {
  width: 100%;
  background:var(--color-primary);
  border:0;
  cursor:pointer;
  color:white;
  font-size:22px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin: 18px 0px;
  font-weight:700;
}
.login-container input:focus {
  border:2px solid var(--color-tertiary);
}
.login-container button:hover { background: var(--color-tertiary); }



@media (max-width: 1000px) {
  .login-div {
    height: calc(100vh - 60px );
    padding: 0px 20px;
  }
.login-container {
transform: translateY(-50px);
margin: 20px;
width: 330px;
padding: 30px;
}
}
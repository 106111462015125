
.home-div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8em;
  min-height: 100vh;
  color: #292929;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 49px 0px; */
}
/* .home-div::before {
  content : "";
  position: absolute;
  left    : 0;
  bottom  : 0;
  height  : 1px;
  transform: translateX(80%);
  width   : 40%;
  border-bottom:1px solid #dddddd;
  border-radius: 15px;
} */
.dev-img {
  width: 40vw;
}
.title-div{
  text-align: left;
  width: 50vw;
}
.image-div {
  width: 50vw;
}
.btn-div {
  display: inline-block;
  margin: 2em 0;
}
.home-div h1 {
  margin: 0px;
  font-size: 5vw;
  color: var(--color-primary);
  margin-top: 20px;
  color: #292929;
  text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
}
.home-div h2 {
  margin: 0px;
  font-size: 4vw;
  text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
}
.home-div p {
  font-size: 1vw;
  line-height: 30px;
  margin-top: 40px;
}
/* @media only screen and (min-width: 600px) and (max-width: 100px) {
  .home-div h1 {
  font-size: 8vw;
  }
  .home-div h2 {
    margin: 0px;
    font-size: 8vw;
    text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
  }
  .home-div p {
    margin-top: 45px;
    font-size: 3vw;
  }
} */
@media (max-width: 1300px) {
    .home-div h1 {
  font-size: 5vw;
  }
  .home-div h2 {
    margin: 0px;
    font-size: 4vw;
    text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
  }
  .home-div p {
    margin-top: 45px;
    font-size: 1.5vw;
  }
}
@media (max-width: 1000px) {
  .home-div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 80px);
    /* width: 100vw; */
    color: #292929;
    padding: 0;
    margin-top: -50px;
  }
  .title-div {
    width: inherit;
    padding-left: 20px;
  }
  .dev-img {
    display: none;
  }
  .home-div h1 {
  font-size: 8vw;
  }
  .home-div h2 {
    margin: 0px;
    font-size: 7vw;
    text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
  }
  .home-div p {
    margin-top: 45px;
    font-size: 2vw;
    line-height: 40px;
  }
}
@media (max-width: 700px) {
    .home-div h1 {
  font-size: 8vw;
  }
  .home-div h2 {
    margin: 0px;
    font-size: 7vw;
    text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
  }
  .home-div p {
    margin-top: 45px;
    font-size: 2.3vw;
  }
}
@media (max-width: 500px) {
    .home-div h1 {
  font-size: 11vw;
  }
  .home-div h2 {
    margin: 0px;
    font-size: 10vw;
    text-shadow: 0px 10px 25px rgba(0, 0, 0, 0.20);
  }
  .home-div p {
    margin-top: 45px;
    font-size: 4vw;
  }
}
.project-div {
  min-height: 100vh;
  padding: 120px 8em 80px 8em;
  background-color: rgb(237, 243, 255);
}

.projectcard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  max-width: 840px;
  margin-inline: auto;
}

@media (max-width: 1000px) {
  .project-div {
    /* padding: 50px 2em; */
    padding: 80px 20px;
  }
.projectcard-container {
    padding-bottom: 40px;
}
}
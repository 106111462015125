.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination button {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #f0f0f0;
}

.pagination button.active {
  background-color: var(--color-primary);
  color: #fff;
  border: 1px solid var(--color-primary);
}

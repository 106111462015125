.skill-div {
  min-height: 100vh;
  /* background-color: rgb(237, 243, 255); */
  padding: 120px 8em 80px 8em;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 49px 0px; */
}

.skillcard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 24px;
  max-width: 840px;
  margin-inline: auto;
}

@media (max-width: 1000px) {
  .skill-div {
    /* height: 100vh; */
    padding: 80px 20px;
  }
.skillcard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 24px;
  max-width: 840px;
  margin-inline: auto;
  padding-bottom: 40px;
}
}
.post-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
}
.post-form input, textarea {
  color: var(--color-tertiary);
  font-weight:500;
  font-size: 18px;
  line-height: 22px;
  background-color: transparent;
  border:2px solid var(--color-primary);
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}
.post-form input, .post-form textarea {
  margin: 20px;
  width: 600px;
  padding: 10px 20px;
  margin-left: 30px;
}


.submit-btn-blog {
  background-color: var(--color-primary) !important;
  border:0 !important;
  cursor:pointer !important;
  color:white !important;
  font-size:22px !important;
  padding-top:12px !important;
  padding-bottom:12px !important;
  transition: all 0.3s !important;
  margin-top:-4px !important;
  font-weight:600 !important;
}
[type="submit"]:hover { background: var(--color-tertiary) !important; }



@media (max-width: 900px) {
  .post-form input, .post-form textarea {
  margin: 20px;
  max-width: 80vw;
  padding: 10px 20px;
  margin-left: 30px;
}
}
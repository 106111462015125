.contact-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 130px 0em 0px 0em;
  /* background-color: rgb(237, 243, 255); */
}
.contact-container {
  padding-bottom: 80px;
  padding: 0em 2em;
}

form { 
  max-width:420px;
  margin:20px auto;
  padding-bottom: 30px;
}

.feedback-input {
  color: var(--color-tertiary);
  font-weight:500;
  font-size: 18px;
  line-height: 22px;
  background-color: transparent;
  border:2px solid var(--color-primary);
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}

.feedback-input:focus { border:2px solid var(--color-tertiary); }

textarea {
  height: 150px;
  line-height: 150%;
  resize:vertical;
}

[type="submit"] {
  width: 100%;
  background:var(--color-primary);
  border:0;
  cursor:pointer;
  color:white;
  font-size:22px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
}
[type="submit"]:hover { background: var(--color-tertiary); }



@media (max-width: 1000px) {
  .contact-div {
    padding: 50px 0em 0px 0em;
  }
}
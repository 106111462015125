.about-div {
  min-height: 100vh;
  padding: 120px 8em 50px 8em;
  background-color: rgb(237, 243, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 49px 0px; */
}


.about-card {
  /* background-color: blanchedalmond; */
  position: absolute;
  /* background-color: #fff; */
  top: 52%;
  transform: translate(0, -50%);
  width: 60vw;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px; */
}
.about-left {
  width: 60vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 30px;
  padding: 20px;
  text-align: justify;
}
.about-left h4 {
  margin: 0;
  padding: 10px 0;
  text-align: center;
  font-size: 26px;
}
.about-left p {
  padding: 0 20px 20px;
}
.about-right {
  width: 40vw;
  height: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-content: center;
}
.a-box {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: .2s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px;
}
.a-box a {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: .2s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px;
  text-decoration: none;
  color: var(--color-tertiary);

}
.s-box {
  background-color: var(--color-secondary);
}
.p-box {
  background-color: var(--color-secondary);
}
.c-box {
  background-color: var(--color-primary);
}
.g-box {
  background-color: var(--color-primary);
  /* pointer-events: none; */
}

.a-box:hover {
  transform: scale(1.1);
}
.s-box:hover {
  background-color: var(--color-primary);
}
.p-box:hover {
  background-color: var(--color-primary);
}
.c-box:hover {
  background-color: var(--color-secondary);
}
@media (max-width: 1000px) {
  .about-div{
    padding: 80px 10px;
    min-height: 100vh;
  }

  .about-card {
    transform: none;
    position: static;
    height: fit-content;
    width: 92vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 40px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 25px 0px; */
  }
  .about-right {
    /* background-color: aqua; */
    width: inherit;
    height: fit-content;
    display: flex;
    flex-direction: column;
    column-count: 1;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .about-left {
    width: inherit;
    /* background-color: rgb(158, 158, 158); */
    height: fit-content;padding: 0px;
  }
  .about-left h4 {
    margin-top: 15px;
  }
  .about-left p {
    margin: 0 20px 20px;
  }
  .a-box {
  margin: 10px 20px;
  width: 70%;
  height: 60px;
  transition: none;
  /* pointer-events: none; */
  }
  .a-box a {
  margin: 0;
  width: 100%;
  height: 60px;
  transition: none;

}
  .s-box:hover {
  transform: scale(1);
  }
  .p-box:hover {
  transform: scale(1);

  }
  .c-box:hover {
  transform: scale(1);

  }
  .h-box:hover {
  transform: scale(1);

  }
}